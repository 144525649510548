/* Styles globals */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Ubuntu', sans-serif;
}
.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 90%;
  max-width: 1100px;
  height: 100%;
  margin: auto;
  gap: 10px;
  overflow: hidden;
  align-items: center;
}
.weight-300 {
  font-weight: 300;
}
.weight-400 {
  font-weight: 400;
}
.weight-700 {
  font-weight: 700;
}
.img {
  width: 95%;
  max-width: 450px;
}
.subtitle {
  font-size: 2em;
}

/* Styles call to action */
.btn-call-to-action {
  width: 130px;
  background-image: linear-gradient(
    to right top,
    #2176cc,
    #1b6ab8,
    #145ea5,
    #0e5292,
    #074780
  );
  color: white;
  display: inline-block;
  text-align: center;
  padding: 10px 0;
  text-decoration: none;
  border-radius: 10px;
}
.btn-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 3px solid #074780;
  color: #074780;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  width: 120px;
}

.btn-contact img {
  width: 50%;
  margin-bottom: 10px;
}

/* Styles header */
.content-navbar {
  position: fixed;
  width: 100%;
  transition: all 0.2s ease-in;
}
.background-navbar {
  background: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.background-navbar .navbar a {
  display: block;
}
.navbar {
  display: grid;
  width: 90%;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1100px;
  margin: auto;
  height: 80px;
  align-items: center;
}
.navbar a {
  display: none;
  grid-column: -1;
  transition: all 0.3s ease-in;
}
.navbar .brand {
  height: 60px;
}
.header {
  height: 600px;
}
.img-header {
  width: 100%;
  height: auto;
  grid-column: 7/13;
}
.background-circle {
  position: absolute;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 600px;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: -1;
}
.background-circle img {
  position: absolute;
}
.background-circle .circle-lg {
  transform: translate(80px, -150px);
  z-index: -1;
  height: 650px;
}
.background-circle .circle-sm {
  transform: translate(80px, -100px);
  z-index: 1;
  height: 350px;
}
.header-texts {
  grid-column: 1/7;
  padding-right: 25px;
}
.header-texts h1 {
  color: #074780;
  margin-bottom: 20px;
}
.header-texts p {
  text-align: justify;
  font-weight: lighter;
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #666666;
}

/* Styles about us */
.about-us {
  padding: 80px 0;
}
.text-about-us {
  grid-column: 7/13;
  grid-row: 1/-1;
}
.text-about-us .subtitle {
  color: #074780;
  margin-bottom: 10px;
}
.img-about-us {
  grid-column: 1/7;
  grid-row: 1/-1;
}
.paragraph {
  font-weight: lighter;
  font-size: 1.2em;
  color: #666666;
  margin-bottom: 15px;
  text-align: justify;
}

/* Styles our team */
.our-team {
  height: auto;
  padding: 80px 0;
  background-image: linear-gradient(
    to right top,
    #2176cc,
    #1b6ab8,
    #145ea5,
    #0e5292,
    #074780
  );
}
.our-team .subtitle {
  grid-column: 1/-1;
  text-align: center;
  padding-bottom: 40px;
  color: #fff;
}
.items {
  grid-column: 1/-1;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.item {
  width: 23%;
  height: 320px;
  background: linear-gradient(to bottom, #e7e7e7 25%, #fff 25%);
  overflow: hidden;
  border-radius: 10px;
  text-align: center;
  padding: 20px 10px;
}

.item img {
  width: 120px;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background: #fff;
}
.item h3 {
  font-size: 1.3em;
  color: #074780;
  margin-top: 25px;
}
.item > span {
  font-weight: 400;
  color: #999999;
  padding: 0 10px;
}
.item p {
  margin-top: 10px;
  font-weight: lighter;
  color: #616161;
  font-size: 1em;
}

/* Styles contact */
.contact {
  padding: 80px 0;
}
.contact .subtitle {
  grid-column: 1/-1;
  text-align: center;
  padding-bottom: 40px;
  color: #074780;
}
.content-contact {
  grid-column: 7/13;
  grid-row: 1/-1;
}
.img-contact {
  grid-column: 1/7;
  grid-row: 1/-1;
}
.content-btn {
  display: flex;
  justify-content: space-evenly;
}
.data-contact {
  padding: 25px 0;
  text-align: center;
}
.data-contact h3 {
  color: #074780;
  margin-bottom: 10px;
}
.data-contact .info {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
  background-color: #e7e7e7;
  border-radius: 10px;
  color: #616161;
}

/* Styles footer */
.footer {
  padding: 20px 10px;
  background-color: #e7e7e7;
  color: #616161;
  text-align: center;
}
.heart-shape {
  display: inline-block;
  margin: 0 7px;
  position: relative;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #074780;
}
.heart-shape:before,
.heart-shape:after {
  position: absolute;
  width: 10px;
  height: 10px;
  content: '';
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: #074780;
}
.heart-shape:before {
  bottom: 0px;
  left: -5px;
}
.heart-shape:after {
  top: -5px;
  right: 0px;
}

@media screen and (max-width: 800px) {
  .header {
    height: auto;
  }
  .grid {
    gap: 35px;
  }
  .header-texts,
  .img-header {
    grid-column: 1/-1;
  }
  .header-texts {
    background: #f5f5f5;
    margin-top: 140px;
    padding: 20px;
    border-radius: 10px;
  }
  .img-header {
    text-align: center;
  }

  .about-us {
    height: auto;
  }
  .text-about-us,
  .img-about-us {
    grid-column: 1/-1;
    grid-row: auto;
  }
  .img-about-us {
    text-align: center;
  }

  .item {
    width: 45%;
    margin: 10px 0;
  }

  .contact {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  .content-contact,
  .img-contact {
    grid-column: 1/-1;
    grid-row: auto;
    width: 100%;
  }
  .img-contact {
    text-align: center;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 400px) {
  .navbar {
    height: 60px;
  }
  .navbar .brand {
    height: 40px;
  }
  .navbar a {
    display: block;
  }
  .content-navbar {
    background: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }
  .img {
    max-width: 200px;
  }
  .header {
    height: auto;
  }
  .header-texts {
    background: none;
    margin-top: 90px;
    padding: 20px;
  }
  .header-texts h1 {
    font-size: 1.5em;
    color: white;
  }
  .header-texts .paragraph {
    color: white;
    text-align: left;
  }
  .header-texts .btn-call-to-action {
    background: white;
    color: #074780;
  }
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
  .our-team .subtitle {
    padding-bottom: 0;
  }
  .item {
    width: 95%;
    margin: 10px 0;
  }

  .contact,
  .our-team {
    padding: 40px 0;
  }
  .btn-contact {
    border: 2px solid #074780;
    width: 90px;
  }
  .data-contact .info {
    flex-direction: column;
  }
  .data-contact .info p {
    padding: 5px 0;
  }
}
